<template>
  <BOverlay
    id="scroll"
    spinner-variant="primary"
    variant="light"
    blur="0"
    opacity=".5"
    rounded="sm"
    :show="loading"
  >
    <div
      id="table"
      style="height: calc(100vh - 175px); overflow-y: scroll;"
    >
      <!-- @scroll="handleScroll" -->
      <b-table-simple
        id="report-table"
        bordered
      >
        <b-thead>
          <b-tr>
            <b-th rowspan="2">
              No
            </b-th>
            <b-th rowspan="2">
              Name
            </b-th>
            <b-th rowspan="2">
              Product
            </b-th>
            <b-th rowspan="2" />
            <b-th colspan="7">
              <div class="flex">
                <span class="ms-auto">Week {{ currentWeek }}</span>
                <div class="flex gap-[5px] ms-auto">
                  <button
                    class="border rounded px-[5px] bg-[#fff] disabled:bg-transparent"
                    :disabled="currentWeek === 1"
                    @click="currentWeek--"
                  >
                    <span class="k-arrow-left-2" />
                  </button>
                  <button
                    class="border rounded px-[5px] bg-[#fff] disabled:bg-transparent"
                    :disabled="endWeekByLastDay === lastDay"
                    @click="currentWeek++"
                  >
                    <span class="k-arrow-right-2" />
                  </button>
                </div>
              </div>
            </b-th>
            <b-th
              colspan="2"
              class="text-center"
            >
              Average
            </b-th>
          </b-tr>
          <b-tr>
            <b-th
              v-for="date in 7"
              :key="date"
              class="px-2 py-[10px] text-center"
            >
              {{ dateHead(date) }}
            </b-th>
            <b-th class="px-2 py-[10px] text-center">
              Weekly
            </b-th>
            <b-th class="px-2 py-[10px] text-center">
              Monthly
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <template v-for="(item, index) in items">
            <template v-for="(product, idx) in item.products">
              <b-tr :key="index+'-a'+idx">
                <b-th
                  v-if="idx === 0"
                  :rowspan="3 * item.products.length"
                >
                  {{ index + 1 }}
                </b-th>
                <b-th
                  v-if="idx === 0"
                  :rowspan="3 * item.products.length"
                  class="text-left align-top"
                >
                  <div class="flex flex-col gap-[14px]">
                    <span class="font-[500]">
                      {{ item.full_name }}
                    </span>
                    <span class="text-xs">
                      {{ item.talent_lead }}
                    </span>
                    <span class="text-sm">
                      {{ item.partner_id }} - {{ item.partner_name }}
                    </span>
                    <button
                      class="border-amber-700 outline-none px-[8px] py-[2px] w-fit text-sm rounded border-primary text-primary font-[600]"
                      @click="toggleWithdrawer(item.report_id)"
                    >
                      Target <span
                        v-if="item.is_empty_target"
                        class="k-info-circle text-primary"
                      />
                    </button>
                  </div>
                </b-th>
                <b-th rowspan="3">
                  {{ product.name }}
                </b-th>
                <b-th>Total Leads</b-th>
                <b-td
                  v-for="(data, idxData) in fillProducts(product.details).slice(startWeekIndex, endWeekIndex)"
                  :key="idxData"
                  class="relative p-0 overflow-hidden"
                >
                  <span
                    v-b-tooltip.hover.rightbottom.html="data.total_leads > 0 && { title: tooltip(data), customClass: 'tooltip-custom' }"
                    class="leads-mark"
                    :class="data.total_leads > 0 && currentIndex(idxData) <= lastDay ? (data.total_leads >= product.leads ? 'bg-[#34A770]' : 'bg-[#E31A1A]') : ''"
                  />
                  {{ currentIndex(idxData) > lastDay ? '' : (data.total_leads || '-') }}
                </b-td>
                <b-td>{{ leadsAverage(product.details.slice(startWeekIndex, endWeekIndex)) }}</b-td>
                <b-td>{{ leadsAverage(product.details) }}</b-td>
              </b-tr>
              <b-tr :key="index+'-b'+idx">
                <b-th>Transactions</b-th>
                <b-td
                  v-for="(data, idxData) in fillProducts(product.details).slice(startWeekIndex, endWeekIndex)"
                  :key="idxData"
                  class="relative p-0 overflow-hidden"
                >
                  {{ currentIndex(idxData) > lastDay ? '' : (data.total_transactions || '-') }}
                </b-td>
                <b-td>{{ transactionAverage(product.details.slice(startWeekIndex, endWeekIndex)) }}</b-td>
                <b-td>{{ transactionAverage(product.details) }}</b-td>
              </b-tr>
              <b-tr
                :key="index+'-c'+idx"
                class="bg-[#E2E2E2]"
              >
                <b-th>CR</b-th>
                <b-td
                  v-for="(data, idxData) in fillProducts(product.details).slice(startWeekIndex, endWeekIndex)"
                  :key="idxData"
                  class="relative p-0 overflow-hidden"
                  :class="currentIndex(idxData) > lastDay ? 'bg-white' : !data.total_leads || (data.total_transactions / data.total_leads) * 100 < product.closing_rate ? 'bg-[#FCBEBE]' : 'bg-[#BEFCDE]'"
                >
                  {{ currentIndex(idxData) > lastDay ? '' : (data.total_leads ? ((data.total_transactions / data.total_leads) * 100).toFixed(1) + '%' : '-') }}
                </b-td>
                <b-td :class="closingRateAverage(product.details.slice(startWeekIndex, endWeekIndex)) >= product.closing_rate ? 'bg-[#BEFCDE]' : 'bg-[#FCBEBE]'">
                  {{ closingRateAverage(product.details.slice(startWeekIndex, endWeekIndex)) + '%' }}
                </b-td>
                <b-td :class="closingRateAverage(product.details) >= product.closing_rate ? 'bg-[#BEFCDE]' : 'bg-[#FCBEBE]'">
                  {{ closingRateAverage(product.details) + '%' }}
                </b-td>
              </b-tr>
            </template>
          </template>
        </b-tbody>
      </b-table-simple>
    </div>
    <Rightdrawer
      :is-open.sync="isOpen"
      :data="drawerData"
    />
  </BOverlay>
</template>

<script>
import { komtimAxiosIns } from '@/libs/axios'
import { alertError } from '@toast'
import { YEAR_MONTH } from '@/libs/filterDate'
import Rightdrawer from './Rightdrawer.vue'

export default {
  components: {
    Rightdrawer,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['periodeTable', 'talentLeadTable', 'keyword'],
  data() {
    return {
      loading: false,
      limit: 20,
      offset: 0,
      lastData: false,
      items: [],
      isOpen: false,
      currentWeek: 1,
      drawerData: {
        full_name: '',
        talent_lead: '',
        partner_name: '',
        report_id: 0,
        products: [],
      },
      alertError,
    }
  },
  computed: {
    startWeekIndex() {
      return (this.currentWeek - 1) * 7
    },
    endWeekIndex() {
      return ((this.currentWeek - 1) * 7) + 7
    },
    lastDay() {
      const now = new Date(this.periodeTable)
      const year = now.getFullYear()
      const month = now.getMonth()
      return new Date(year, month + 1, 0).getDate()
    },
    endWeekByLastDay() {
      return this.endWeekIndex < this.lastDay ? this.endWeekIndex : this.lastDay
    },
  },
  watch: {
    periodeTable() {
      this.getData()
    },
    talentLeadTable() {
      this.getData()
    },
    keyword() {
      this.handleSearch()
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    dateHead(date) {
      const lastIndex = date + 7 * (this.currentWeek - 1)
      return lastIndex <= this.lastDay ? lastIndex : ''
    },
    currentIndex(idx) {
      return this.startWeekIndex + idx + 1
    },
    async getData() {
      this.loading = true
      this.offset = 0
      const params = `offset=${this.offset}&limit=${this.limit}&search=${this.keyword}&date=${YEAR_MONTH(this.periodeTable)}&talent_lead=${this.talentLeadTable.join(',')}`
      const url = `v1/talent_performance/list?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          this.items = data
          this.offset = data.length

          if (data.length < this.limit) {
            this.lastData = true
          } else {
            this.lastData = false
          }
        })
        .catch(err => {
          this.loading = false
          this.alertError(err)
        })
    },
    async getNextData() {
      this.loading = true
      if (!this.lastData) {
        const params = `offset=${this.offset}&limit=${this.limit}&search=${this.keyword}`
        const url = `v1/talent_performance/list?${params}`

        await komtimAxiosIns
          .get(url)
          .then(res => {
            const { data } = res.data
            this.items.push(...data)
            this.offset += data.length
            this.loading = false
            if (data.length < this.limit) {
              this.lastData = true
            }
          })
          .catch(err => {
            this.alertError(err)
          })
      }
    },
    async toggleWithdrawer(id) {
      const item = this.items.find(i => i.report_id === id) || this.drawerData
      this.drawerData = {
        user_id: item.user_id,
        full_name: item.full_name,
        talent_lead: item.talent_lead,
        partner_name: item.partner_name,
        report_id: id,
        products: item.products.map(p => ({ product_id: p.product_id, name: p.name })),
      }
      this.isOpen = true
    },
    transactionAverage(arr) {
      const withValue = arr.filter(a => a.total_transactions > 0)
      const result = withValue.length === 0 ? 0 : withValue.reduce((a, b) => a + b.total_transactions, 0) / withValue.length
      return result.toFixed(1)
    },
    leadsAverage(arr) {
      const withValue = arr.filter(a => a.total_leads > 0)
      const result = withValue.length === 0 ? 0 : withValue.reduce((a, b) => a + b.total_leads, 0) / withValue.length
      return result.toFixed(1)
    },
    closingRateAverage(arr) {
      const temp = arr.map(e => ({ ...e, cr: e.total_leads ? ((e.total_transactions / e.total_leads) * 100) : 0 }))
      const withValue = temp.filter(a => a.total_leads > 0)
      const result = withValue.length === 0 ? 0 : withValue.reduce((a, b) => a + b.cr, 0) / withValue.length
      return result.toFixed(1)
    },
    tooltip(data) {
      return `<div class="text-left"><strong> Detail </strong></div><div class="text-left">CBT: ${data.total_cbt}</div><div class="text-left">Deskripsi: ${data.description || '-'}</div>`
    },
    handleSearch: _.debounce(function search() {
      this.getData()
    }, 1000),
    fillProducts(products) {
      const dummyProducts = {
        product_target_id: 0,
        report_date: '2024-11-05T00:00:00+07:00',
        total_leads: 0,
        total_transactions: 0,
        total_cbt: null,
        description: '',
        created_at: '0001-01-01T00:00:00Z',
      }

      const filledItems = [...products]

      while (filledItems.length < 35) {
        filledItems.push(dummyProducts)
      }

      return filledItems
    },
  },
}
</script>

<style scoped>
.leads-mark {
  position: absolute;
  top: -7px;
  right: -7px;
  transform: rotate(45deg);
  width: 15px;
  height: 15px;
}

table#report-table th {
  font-weight: normal;
  text-align: left;
}

table#report-table td {
  padding: 0;
  text-align: center;
}

.tooltip-custom * {
  text-align: left;
}
</style>
